import { ListEntity, MessageEntity, ProductEntity } from 'models'
import { instanceAxios } from 'utils'
import { ApiRequest } from './interface'
import { CommentEntity } from 'models/comment'

const index = async (params: ApiRequest.message.index): Promise<ListEntity<MessageEntity>> => instanceAxios.get('/messages', { params })

const update = async (id: string, params: ApiRequest.message.update) => instanceAxios.patch(`/messages/${id}`, params)

const create = async (params: ApiRequest.message.create) => instanceAxios.post('/messages', params)

const del = async (id: string): Promise<ListEntity<MessageEntity>> => instanceAxios.get(`/messages/${id}`)

export default {
  index,
  update,
  create,
  delete: del
}
