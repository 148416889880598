import area from './area'
import campaign from './campaign'
import category from './category'
import color from './color'
import comment from './comment'
import group_permission from './group_permission'
import me from './me'
import order from './order'
import org_unit from './org_unit'
import portal from './portal'
import product from './product'
import report from './report'
import store from './store'
import tester from './tester'
import user_portal from './user_portal'
import image from './image'
import notice_arrival from './notice_arrival'
import excel_template from './excel_template'
import reorder from './reorder'
import new_product_order from './new_product_order'
import message from './message'
const Api = {
  me,
  portal,
  group_permission,
  store,
  area,
  user_portal,
  org_unit,
  campaign,
  product,
  order,
  tester,
  report,
  category,
  comment,
  color,
  image,
  notice_arrival,
  excel_template,
  reorder,
  new_product_order,
  message
}
export * from './interface'
export default Api
